@charset "UTF-8";

.wp-block {
    max-width: 1000px;
}

.wp-block[data-align="wide"] {
    max-width: 1400px;
}

.wp-block[data-align="full"] {
    max-width: none;
}

/*Ganesa Editor Styles*/
body{
    margin: auto;
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
    color: #3a3a3a;
    background: #fff;
    font-family: "Josefin Sans", Arial, serif;
    height: 100%;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}


.editor-post-title__block .editor-post-title__input{
    font-size: 42px;
    font-weight: 600;
    color: #000;
    font-family: "Josefin Sans";
}
.editor-styles-wrapper{

}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 400;
    color: #000;
}

h1, .h1 {
    font-size: 41px;
}
h2, .h2 {
    font-size: 34px;
}
h3, .h3 {
    font-size: 28px;
}
h4, .h4 {
    font-size: 20px;
}
h5, .h5 {
    font-size: 16px;
}

h1,h2,h3,h4,h5,h6{
    > a{
        color:#000;
    }
}
th{
    font-weight: 600;
    color: #000;
}
dd{
    margin-bottom: 30px;
}
dt {
    padding-bottom: 10px;
}
address{
    margin-bottom: 30px;
}
select{
    height:50px;
    padding: 5px 10px;
    border: 1px solid #f3f3f3;
    font-size: 16px;
}
.wp-block code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 0px;
}

label{
    color:#000 ;
    font-size: 16px;
    display:inline-block;
    vertical-align: middle;
    font-weight: 300;
}

b,strong{
    font-weight: 600;
    color:#000 ;
}
.radio, .checkbox{
    display: inline-block;
}
blockquote {
    font-size: 24px;
    line-height: 36px;
    padding:0px 50px;
    color:#000 ;
    font-weight: 400;
    &:before{
        content: "";
        top:0px;
        height: 100%;
        left: 0;
        width: 4px;
        position: absolute;
        color:#000 ;
    }
}

p{
    font-size: 22px;
    line-height: 36px;
}
p{
    margin: 0 0 30px;
}
img{
    max-width: 100%;
    height: auto;
}
.img.size-full{
    height: auto;
    max-width: 100%;
}
.alignright{
    float: right;
}
.alignleft{
    float: left;
}
.aligncenter{
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
input{
    -webkit-appearance: none;
    appearance: none;
    border-radius:0px;
    height: 50px;
    font-size: 16px;
    padding: 7px 15px;
    background: white ;
    border: 1px solid #e1e1e1;

    &:focus{
        border: 1px solid #888890;
    }
}
textarea,button, select{
    -webkit-appearance: none;
    appearance: none;
    border-radius:0px;
    font-size: 16px;
    padding: 7px 15px;
    background: white ;
    border: 1px solid #ebebeb;
    &:focus{
        border: 1px solid #888890;
    }
}
.form-control{
    -webkit-appearance: none;
    appearance: none;
}
input[type="submit"]{
    background-color: #fff;
    border-color: #fff;
    border-style: solid;
    color: #000;
    cursor: pointer;
    font-size: 20px;
    height: auto;
    line-height: 30px;
    vertical-align: top;
    font-weight: bold;
    letter-spacing: 1px;
}
/*video responsive*/
.video-responsive {
    height: 0;
    padding-top: 0;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
    embed,
    iframe,
    iframe,
    video{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border: none;
    }
}

.entry-content img,
.entry-content iframe,
.entry-content object,
.entry-content embed {
    max-width: 100%;
}

.audio-responsive iframe {
    width: 100%;
    height: 126px;
}
/*---clear css bootstrap --*/
// .dropdown-toggle
.btn-group.open .dropdown-toggle{
    box-shadow: none;
}
.alert{
    margin: 20px 0;
    padding: 20px;
}

.tags-list a, .tagcloud a {
    background:#FAFAFA;
    border: 1px solid #ddd;
    color: #3a3a3a;
    display: inline-block;
    margin-bottom: 5px;
    left: 3px;
    padding: 7px 10px;
    font-size: 14px;
    &:hover{
        color: #000;
    }
}
dt{
    font-weight: 600;
    color: #000;
}
.required {
    border: 0 none;
    color: #ff0000;
    font-weight: 700;
}

//Tag
.tagcloud a{
    font-size: 14px !important;
    color: #3a3a3a;
}

pre{
    border: none;
    border-radius: 0;
}

//table
table{
    background: #FFF;
    color: #212121;
    border: 1px solid #e5e5e5;
    thead{
        background: #FAFAFA;
        > tr > th{
            border-bottom: 1px solid #e5e5e5;
        }
    }
    th{
        font-weight: 400;
    }
    tbody{
        font-size: 16px;
    }
}
table {
    background: #FFF;
    color: #212121;
    border: 1px solid #e5e5e5;
}
table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 22px;
}
table thead {
    background: #FAFAFA;
}
table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tfoot > tr > td {
    padding: 30px;
}
table thead > tr > th {
    border-bottom: 1px solid #e5e5e5;
}
#today{
    a{
        color: #FFF;
    }
}
